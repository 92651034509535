globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"0822fff-dev"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "/auth";
import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  tracesSampleRate: 1,
  profilesSampleRate: 1.0,
  debug: false,
  replaysOnErrorSampleRate: parseFloat(process.env.SENTRY_REPLAY ?? '0'),
  environment: process.env.FULL_ENV,
  release: process.env.SENTRY_RELEASE,
  integrations: [
    Sentry.replayIntegration,
    Sentry.browserProfilingIntegration,
    Sentry.browserTracingIntegration,
    Sentry.rewriteFramesIntegration({
      iteratee: (frame) => {
        if (frame.filename) {
          frame.filename = frame.filename.replace(/^app:\/\/\//, '');
          frame.filename = frame.filename.replace(/~\/_next\/static/g, '');
          frame.filename = frame.filename.replace(/_next\/static/g, '');
          frame.filename = frame.filename.replace(/_next\/server/g, '');
          frame.filename = frame.filename.replace(/\/server/g, '');
        }
        return frame;
      },
    }),
  ],
  ignoreErrors: [
    /(?:^|\W)Reference Error: Can't find variable __OISA(?:$|\W)/gm,
    /(?:^|\W)No identifiers allowed directly after numeric literal(?:$|\W)/gm,
  ],
});
